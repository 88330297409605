import { Page } from '@src/components/Page'
import { IPhone } from '@src/components/IPhone'
import { Button, Flexbox, Grid, Header, Paragraph } from '@stage-ui/core'

const S01Header = () => {
  return (
    <Page>
      <Grid
        templateColumns={['1fr 1fr', '*', '1fr']}
        style={{
          minHeight: '100vh',
        }}
        alignItems={['center', 'center', 'end']}
      >
        <Grid
          gap="1rem"
          templateColumns="1fr 1fr"
          position="absolute"
          style={{
            right: '-20%',
            transform: 'rotate(-30deg)',
          }}
        >
          <IPhone scale={3} screen={1} />
          <IPhone scale={3} screen={2} t="40%" />
          <IPhone scale={3} screen={3} />
        </Grid>
        <Flexbox
          column
          justifySelf="center"
          backgroundColor={['transparent', 'transparent', 'surface']}
          w={['32rem', '32rem', 'unset']}
          pb={['0rem', '0rem', '4rem']}
          pt={['0rem', '0rem', '2rem']}
          px={['unset', 'unset', '2rem']}
          mb="1rem"
          borderRadius="2rem"
          // @ts-expect-error
          shadow={['none', 'none', 'xl']}
          alignItems={['flex-start', 'flex-start', 'center']}
          style={{
            zIndex: 1,
          }}
        >
          <Header
            align={['start', 'start', 'center']}
            weight={700}
            size={['4.5rem', '*', '3rem']}
            mb="m"
            lineHeight={1.1}
            style={{ whiteSpace: 'pre-wrap' }}
          >
            The Future{`\n`}of Gratuity
          </Header>
          <Paragraph
            mt="1rem"
            align={['start', 'start', 'center']}
            weight={400}
            lineHeight={1.5}
            size={['1.5rem', '*', '1.25rem']}
          >
            Discover how our app streamlines the tipping process for customers and
            waitstaff alike
          </Paragraph>
          <Flexbox mt="3rem" direction={['row', 'row', 'row', 'column']}>
            <Button w="16rem" mt={['xl', '*', 'l']} label="Sign up your business" />
            <Button
              ml={['m', '*', '*', 0]}
              w="16rem"
              mt={['xl', '*', 'l']}
              decoration="outline"
              color="onSurface"
              borderColor="gray200"
              label="Leave tips"
              onClick={() => {
                window.location.href = 'https://pay.etips.ee'
              }}
            />
          </Flexbox>
        </Flexbox>
      </Grid>
    </Page>
  )
}

export default S01Header
