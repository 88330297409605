/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import IPhoneImage from '@assets/images/iphone.png'
import { Block, Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

import screen1_1 from '@assets/images/screen1_1.png'
import screen1_2 from '@assets/images/screen1_2.png'
import screen1_3 from '@assets/images/screen1_3.png'

import screen2_1 from '@assets/images/screen2_1.png'
import screen2_2 from '@assets/images/screen2_2.png'
import screen2_3 from '@assets/images/screen2_3.png'

import screen3_1 from '@assets/images/screen3_1.png'
import screen3_2 from '@assets/images/screen3_2.png'
import screen3_3 from '@assets/images/screen3_3.png'

import { useEffect, useState } from 'react'

const images = [
  [screen1_1, screen2_2, screen3_3],
  [screen2_1, screen3_2, screen1_3],
  [screen3_1, screen1_2, screen2_3],
]

type IPhoneProps = {
  scale?: number
  screen: 1 | 2 | 3
} & FlexboxTypes.Props

export const IPhone = (props: IPhoneProps) => {
  const { scale = 1, screen, children, ...restProps } = props

  const [state, setState] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      if (state === 2) {
        setState(0)
      } else {
        setState(state + 1)
      }
    }, 3000)
  }, [state])

  return (
    <Flexbox
      {...restProps}
      style={{
        width: `calc(6rem * ${scale})`,
        height: `calc(12.5rem * ${scale})`,
        // @ts-ignore
        ...restProps.style,
      }}
    >
      <Block
        style={{
          background: `url(${images[state][screen - 1]}) no-repeat`,
          transition: 'all 0.2s ease-in-out',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          width: '100%',
          height: '95.4%',
          position: 'absolute',
          top: '2%',
          right: '0%',
          borderRadius: '3rem',
        }}
      />
      <Block
        style={{
          background: `url(${IPhoneImage}) no-repeat`,
          backgroundSize: '100%',
          width: '100%',
          height: '100%',
        }}
      />
      {children}
    </Flexbox>
  )
}
