import { Flexbox, Paragraph, Text } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

export const NumberTextBlock = (
  props: FlexboxTypes.Props & { number: number; isDark?: boolean },
) => {
  const { children, number, isDark, ...restProps } = props
  return (
    <Flexbox alignItems="center" {...restProps}>
      <Text
        w="4rem"
        size="3.125rem"
        weight={900}
        color={isDark ? 'surface' : 'onSurface'}
      >
        {number}
      </Text>
      <Paragraph
        color={isDark ? 'surface' : 'onSurface'}
        weight={600}
        size="1.125rem"
        style={{
          opacity: 0.8,
          lineHeight: 1.8,
          maxWidth: '44rem',
          whiteSpace: 'pre-wrap',
        }}
      >
        {children}
      </Paragraph>
    </Flexbox>
  )
}
