import React from 'react'
import S01Header from './sections/S01_Header'
import S02Features from './sections/S02_Features'
import S03HowItWorks from './sections/S03_HowItWorks'
import S04ThatsIt from './sections/S04_ThatsIt'

const Home = () => {
  return (
    <>
      <S01Header />
      <S02Features />
      <S03HowItWorks />
      <S04ThatsIt />
    </>
  )
}

export default Home
