import { LineTitle } from '@src/components/LineTitle'
import { NumberTextBlock } from '@src/components/NumberTextBlock'
import { Page } from '@src/components/Page'
import { Block, Flexbox } from '@stage-ui/core'
import { keyframes } from '@emotion/react'

const rotateAnimation = keyframes(`
  0% {
    transform: rotate(0deg);
    border-radius: 1rem;
  }
  50% {
    transform: rotate(180deg);
    border-radius: 10rem;
  }
  100% {
    transform: rotate(360deg);
    border-radius: 1rem;
  }
`)

const S03HowItWorks = () => {
  return (
    <Block backgroundColor="surface" style={{ minHeight: '100vh' }}>
      <Page pt="7rem" pb="1rem">
        <LineTitle mb="15vh">How it works</LineTitle>
        <Flexbox direction={['row', 'row', 'column']}>
          <Block>
            <NumberTextBlock number={1} mb="6vh">
              We make QR codes for waiters. Waiter{`\n`}put the QR on the bill folder or
              check
            </NumberTextBlock>
            <NumberTextBlock number={2} mb="6vh">
              Customers scan QR with their phone camera{`\n`}and choose how much to pay
              for a tip
            </NumberTextBlock>
            <NumberTextBlock number={3} mb="6vh">
              We accept payments and collect{`\n`}rate for waiter service
            </NumberTextBlock>
            <NumberTextBlock number={4} mb="6vh">
              Waiters get their tips in{`\n`}their bank account
            </NumberTextBlock>
          </Block>
          <Block
            w="20rem"
            h="20rem"
            backgroundColor="black"
            ml="20rem"
            style={{
              width: 0,
              height: 0,
              animation: `${rotateAnimation} 20s linear infinite`,
            }}
          />
        </Flexbox>
      </Page>
    </Block>
  )
}

export default S03HowItWorks
